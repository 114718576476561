import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../_services/auth.service';
import {UserService} from '../../_services/user.service';
import {UserDto} from '../../_models/userDto';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {PointsDto} from '../../_models/pointsDto';
import {PointsService} from '../../_services/points.service';
import {LoadingController} from '@ionic/angular';
import { AlertController } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import {MemberPhotoPopupComponent} from '../member-photo-popup/member-photo-popup.page';
import {TranslateService} from '@ngx-translate/core';
import {MemberPointsPopupComponent} from '../member-points-popup/member-points-popup.page';
import * as signalR from '@microsoft/signalr';
import { environment } from '../../../environments/environment';
import {LanguageService} from '../../_services/language.service';

@Component({
  selector: 'app-admin-member',
  templateUrl: 'member.page.html',
  styleUrls: ['member.page.scss'],
})
export class MemberPageComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
    private pointsService: PointsService,
    private sanitizer: DomSanitizer,
    private loadingController: LoadingController,
    private alertController: AlertController,
    private modalController: ModalController,
    public translateService: TranslateService,
    public languageService: LanguageService) {
    translateService.setDefaultLang(languageService.getCurrentLanguage());
    this.photoSrcPath = this.sanitizer.bypassSecurityTrustUrl('/assets/shapes.svg');
    this.user.status = 99;
  }

  userEmail: string = null;
  user: UserDto = new UserDto();
  avatarSrcPath: SafeUrl;
  photoSrcPath: SafeUrl;
  points: PointsDto[] = [];
  public loadingImagePath: SafeUrl;
  public showLoadingImage = true;
  isPhotoModalOpen = false;
  connection: signalR.HubConnection;

  showMandatoryEmptyError1 = false;
  showMandatoryEmptyError2 = false;
  showMandatoryEmptyError3 = false;

  async loadData() {
    if (!this.userEmail) {
      return;
    }

    this.showLoadingImage = true;
    this.user = await this.userService.getUser(this.userEmail);

    this.userService.getAvatar(this.userEmail).then(photo => {
      const blob = new Blob([photo], { type: 'image/jpg'});
      const url = window.URL.createObjectURL(blob);
      this.avatarSrcPath = this.sanitizer.bypassSecurityTrustUrl(url);
      this.showLoadingImage = false;
    });

    this.points = (await this.pointsService.getPoints(this.userEmail)).reverse();
  }

  async ionViewWillEnter() {
    this.user = new UserDto();
    this.avatarSrcPath = null;
    this.photoSrcPath = null;
    this.points = [];
    this.loadingImagePath = null;
    this.showLoadingImage = true;
    this.isPhotoModalOpen = false;

    await this.loadData();

    this.showMandatoryEmptyError1 = false;
    this.showMandatoryEmptyError2 = false;
    this.showMandatoryEmptyError3 = false;

    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(environment.hubUrl, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets
      })
      .configureLogging(signalR.LogLevel.Debug)
      .build();

    this.connection.on('subscription_update', email => {
      if (this.user?.email === email) {
        this.loadData();
      }
    });

    this.connection.start().catch(err => console.log(err));
  }

  ionViewWillLeave() {
    this.connection?.stop().then(() => {});
    this.connection = null;
  }

  back(): void {
    this.router.navigate(['/members']);
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(async params => {
      if (!params.userEmail) {
        const user = await this.userService.getUserById(parseInt(params.userId, 10));
        this.userEmail = user.email;
        await this.loadData();
      } else {
        this.userEmail = params.userEmail;
        await this.loadData();
      }
    });
  }

  async goToAddPoints(id: number) {
    await this.router.navigate(['/add-points'], {
      queryParams: {
        userId: id
      }
    });
  }

  async goToSubtractPoints(id: number) {
    await this.router.navigate(['/subtract-points'], {
      queryParams: {
        userId: id
      }
    });
  }

  async saveChanges() {
    if (!this.user.firstName.trim().length) {
      this.showMandatoryEmptyError1 = true;
    }

    if (!this.user.lastName.trim().length) {
      this.showMandatoryEmptyError2 = true;
    }

    if (!this.user.email.trim().length) {
      this.showMandatoryEmptyError3 = true;
    }

    if (this.showMandatoryEmptyError1 || this.showMandatoryEmptyError2 || this.showMandatoryEmptyError3) {
      return;
    }

    const loading = await this.loadingController.create({
      message: 'Please wait...',
      duration: 1000
    });
    await loading.present();

    await this.userService.updateUser(this.user);
    await this.ionViewWillEnter();
  }

  updateFirstName($event) {
    if (this.user.firstName !== $event.target.value) {
      this.showMandatoryEmptyError1 = false;
    }

    this.user.firstName = $event.target.value.trim();
  }

  updateLastName($event) {
    if (this.user.lastName !== $event.target.value) {
      this.showMandatoryEmptyError2 = false;
    }

    this.user.lastName = $event.target.value.trim();
  }

  updateEmail($event) {
    if (this.user.email !== $event.target.value) {
      this.showMandatoryEmptyError3 = false;
    }

    this.user.email = $event.target.value.trim();
  }

  updatePhone($event) {
    this.user.phone = $event.target.value.trim();
  }

  updateCompany($event) {
    this.user.company = $event.target.value.trim();
  }

  updateAddress($event) {
    this.user.address = $event.target.value.trim();
  }

  async userStateChanged($event) {
    if (this.user.status !== $event.detail.value) {
      const loading = await this.loadingController.create({
        message: 'Please wait...',
        duration: 1000
      });
      await loading.present();
      this.user.status = $event.detail.value;
    }
  }

  userStateCompareWith(o1: number, o2: number) {
    // tslint:disable-next-line:triple-equals
    return o1 == o2;
  }

  async showPointsHistoryItem(item: PointsDto) {
    const modal = await this.modalController.create({
      component: MemberPointsPopupComponent,
      componentProps: {
        date: this.formatPointsDate(item.date),
        points: this.formatPoints(item.count),
        comment: item.comment,
        conditions: item.conditions
      },
      cssClass: 'admin-points-modal-css'
    });

    await modal.present();
  }

  async showPhotoModal() {
    if (!this.isPhotoModalOpen) {
      this.isPhotoModalOpen = true;

      await this.userService.getPhoto(this.userEmail).then(async photo => {
        const blob = new Blob([photo], {type: 'image/jpg'});
        const url = window.URL.createObjectURL(blob);
        this.photoSrcPath = this.sanitizer.bypassSecurityTrustUrl(url);

        const modal = await this.modalController.create({
          component: MemberPhotoPopupComponent,
          componentProps: {
            imageSrcPath: this.photoSrcPath
          }
        });

        modal.onWillDismiss().then(() => {
          this.isPhotoModalOpen = false;
        });

        await modal.present();
      });
    }
  }

  getUserStatusString(statusId: number): string {
    return this.userService.getUserStatusString(statusId);
  }

  formatPointsDate(date: string): string {
    return this.pointsService.formatPointsDate(date);
  }

  formatPoints(points: number): string {
    if (points <= 0) {
      return points.toString();
    }

    return '+' + points;
  }

  getButtonStyle(statusId: number) {
    const color: string = this.userService.getUserStatusColor(statusId);

    return {
      background: color,
      'z-index': 1000
    };
  }

  async openUserStatusSelector() {
    document.getElementById('user-status-selector').click();
  }

  isUserActive(): boolean {
    return this.user && this.userService.isActiveStatus(this.user.status);
  }

  async logOut() {
    window.localStorage.removeItem('id');
    window.localStorage.removeItem('token');
    window.document.cookie = 'Authorization=;';
    await this.router.navigate(['/login'], { replaceUrl: true });
  }
}
