import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {UserDto} from '../_models/userDto';
import {UserStatusDto} from '../_models/userStatusDto';
import {ReepaySubscriptionDto} from '../_models/reepaySubscriptionDto';
import {TranslateService} from '@ngx-translate/core';
import {LanguageService} from './language.service';

@Injectable()
export class UserService {
  baseUrl = `${environment.apiUrl}User/`;

  constructor(
    private http: HttpClient,
    public translateService: TranslateService,
    public languageService: LanguageService) {
    translateService.setDefaultLang(languageService.getCurrentLanguage());
  }

  tryGetUser(id: number, success, failure) {
    this.http
      .get(`${this.baseUrl}id/${id}`, { headers: {Authorization: window.localStorage.getItem('token') }})
      .subscribe(
        data => success && success(data),
        error => failure && failure(error)
      );
  }

  async getDoorCode() {
    const result = this.http.get(`${environment.apiUrl}doors`);
    return await result.toPromise();
  }

  async deleteDoorCode(roomId: string) {
    const result = this.http.delete(`${environment.apiUrl}doors/delete/${roomId}`);
    return await result.toPromise();
  }

  async saveDoorCode(doorId: string, doorPassword: string) {
    const result = this.http.get(`${environment.apiUrl}doors/set/${doorId}/${doorPassword}`);
    return await result.toPromise();
  }

  async getUser(email: string): Promise<UserDto> {
    email = email.replace('+', '%2B');
    const result = this.http.get(`${this.baseUrl}${email}`, { headers: {Authorization: window.localStorage.getItem('token') }});
    return await result.pipe(map(item => item as UserDto)).toPromise();
  }

  async getUserById(id: number): Promise<UserDto> {
    const result = this.http.get(`${this.baseUrl}id/${id}`, { headers: {Authorization: window.localStorage.getItem('token') }});
    return await result.pipe(map(item => item as UserDto)).toPromise();
  }

  async getAvatar(email: string) {
    email = email.replace('+', '%2B');
    const result = this.http.get(`${this.baseUrl}${email}/avatar`,
      { headers: {Authorization: window.localStorage.getItem('token') }, responseType: 'arraybuffer' });
    return await result.toPromise();
  }

  async getPhoto(email: string) {
    email = email.replace('+', '%2B');
    const result = this.http.get(`${this.baseUrl}${email}/photo`,
      { headers: {Authorization: window.localStorage.getItem('token') }, responseType: 'arraybuffer' });
    return await result.toPromise();
  }

  async getUserStatuses(userEmails: string) {
    if (!userEmails || !userEmails.trim().length) {
      return [];
    }

    const result = this.http.post(this.baseUrl + 'status', { emails: userEmails }, { headers: {Authorization: window.localStorage.getItem('token') }});
    return await result.toPromise();
  }

  async getUsers(searchingValue: string | null = null, page: number = 1, orderField: string | null = null, orderDesc: boolean): Promise<UserDto[]> {
    if (typeof searchingValue === 'string' && !searchingValue.trim().length) {
      searchingValue = null;
    }

    const order = orderField && orderField.trim().length ? `/${orderField}/${orderDesc}` : '';

    const result = searchingValue === null
      ? this.http.get(`${this.baseUrl}userspage/null/${page}${order}`, { headers: {Authorization: window.localStorage.getItem('token') }})
      : this.http.get(`${this.baseUrl}userspage/${searchingValue}/${page}${order}`,
        { headers: {Authorization: window.localStorage.getItem('token') }});
    return await result.pipe(map(item => item as UserDto[])).toPromise();
  }

  async getPagesCount(searchingValue: string | null = null): Promise<number> {
    if (typeof searchingValue === 'string' && !searchingValue.trim().length) {
      searchingValue = null;
    }

    const result = searchingValue === null
      ? this.http.get(`${this.baseUrl}pagescount`, { headers: {Authorization: window.localStorage.getItem('token') }})
      : this.http.get(`${this.baseUrl}pagescount/${searchingValue}`,
        { headers: {Authorization: window.localStorage.getItem('token') }});
    return await result.pipe(map(item => item as number)).toPromise();
  }

  async getQr(userId: number) {
    const result = this.http.get(
      this.baseUrl + userId + '/qr',
      { headers: {Authorization: window.localStorage.getItem('token') }, responseType: 'arraybuffer' });
    return await result.toPromise();
  }

  async getSubscriptionHandle(userId: number): Promise<ReepaySubscriptionDto> {
    const result = this.http.get(
      this.baseUrl + userId + '/subscription',
      { headers: {Authorization: window.localStorage.getItem('token') } });
    return await result.pipe(map(item => item as ReepaySubscriptionDto)).toPromise();
  }

  async updateUser(user: UserDto): Promise<UserDto> {
    const result = this.http.put(this.baseUrl, user, { headers: {Authorization: window.localStorage.getItem('token') }});
    return await result.pipe(map(item => item as UserDto)).toPromise();
  }

  async updateUserStatus(userStatus: UserStatusDto) {
    await this.http.put(`${this.baseUrl}status`, userStatus, { headers: {Authorization: window.localStorage.getItem('token') }})
      .toPromise();
  }

  async uploadImage(userId: number, file: File): Promise<number> {
    const fd = new FormData();
    fd.append('file', file, file.name);
    const url = `${this.baseUrl}${userId}/photo`;
    return this.http.post(url, fd, { headers: {Authorization: window.localStorage.getItem('token') }})
      .pipe(map(id => id as number)).toPromise();
  }

  getUserStatusString(statusId: number): string {
    let status = 'statuses.undefined';

    if (statusId === 0) {
      status = 'statuses.non-active';
    } else if (statusId === 1) {
      status = 'statuses.active';
    } else if (statusId === 2) {
      status = 'statuses.on-a-break';
    } else if (statusId === 3) {
      status = 'statuses.non-renewing';
    } else if (statusId === 4) {
      status = 'statuses.cancelled';
    } else if (statusId === 5) {
      status = 'statuses.expired';
    } else if (statusId === 6) {
      status = 'statuses.trial';
    }

    return this.translateService.instant(status);
  }

  isActiveStatus(statusId: number): boolean {
    return statusId === 1 || statusId === 3 || statusId === 6;
  }

  getUserStatusColor(statusId: number): string {
    /* Non-Active */
    if (statusId === 0) {
      return '#BA512C';
    }

    /* Cancelled, On Hold and Expired */
    if (statusId === 4 || statusId === 5) {
      return '#F0D379';
    }

    /* Active, Non Renewing and Trial */
    if (statusId === 1 || statusId === 2 || statusId === 3 || statusId === 6) {
      return '#80A79A';
    }

    return '#D7D4D4';
  }
}
