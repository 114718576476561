import { Injectable } from '@angular/core';
import { LocalStorageService } from './localStorage.service';

@Injectable()
export class LanguageService {
  constructor(public localStorage: LocalStorageService) { }

  private dictionary = {
    'Æ': 'Ae',
    'æ': 'ae',
    'Ø': 'O',
    'ø': 'o',
    'Å': 'Aa',
    'å': 'aa'
  };

  convertStringToLatin(source: string): string {
    return [...source].reduce((result, letter) => {
      return result + (this.dictionary[letter] || letter);
    }, '');
  }

  resetCurrentLanguage() {
    this.localStorage.removeItem('language');
  }

  setCurrentLanguage(language: string) {
    this.localStorage.setItem('language', language);
  }

  getCurrentLanguage(): string {
    /*
    const language: string = this.localStorage.getItem('language');

    if (!language || !language.trim().length) {
      return 'dk';
    }

    return language;
    */

    // Use only Danish for Admin app
    return 'dk';
  }
}
