import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import { map } from 'rxjs/operators';
import {LoginCredentialsDto} from '../_models/loginCredentialsDto';

@Injectable()
export class AuthService {
  baseUrl = `${environment.apiUrl}Auth/`;

  constructor(private http: HttpClient) { }

  async login(email: string, password: string): Promise<any> {
    email = !email ? '' : email.trim();
    password = !password ? '' : password.trim();

    const credentials = new LoginCredentialsDto(email, password);
    const result = this.http.post(this.baseUrl, credentials);
    return await result.pipe(map(item => item as number)).toPromise<any>();
  }
}
