import {Component, Input} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {ModalController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {LanguageService} from '../../_services/language.service';

@Component({
  selector: 'app-member-points-popup',
  templateUrl: 'member-points-popup.page.html',
  styleUrls: ['member-points-popup.page.scss'],
})
export class MemberPointsPopupComponent {
  @Input() date: string;
  @Input() points: string;
  @Input() comment: string;
  @Input() conditions: string[];

  constructor(
    private sanitizer: DomSanitizer,
    private modalController: ModalController,
    public translateService: TranslateService,
    public languageService: LanguageService) {
    translateService.setDefaultLang(languageService.getCurrentLanguage());
  }

  public dismissModal() {
    this.modalController.dismiss();
  }

  async ionViewWillEnter() {
  }

  getConditionsString(): string {
    if (!this.conditions || !this.conditions.length) {
      return '';
    }

    let conditionsString = this.conditions.map(condition => condition.charAt(0).toUpperCase() + condition.slice(1)).join(', ');
    let conditionsSuffix = '';

    if (this.languageService.getCurrentLanguage() === 'dk') {
      conditionsSuffix = 'stand';
    } else {
      conditionsSuffix = this.conditions.length === 1 ? 'condition' : 'conditions';
    }

    conditionsString = [conditionsString, conditionsSuffix].join(' ');
    return conditionsString + '.';
  }
}

