import {Component, Input} from '@angular/core';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {ModalController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {LanguageService} from '../../_services/language.service';

@Component({
  selector: 'app-member-photo-popup',
  templateUrl: 'member-photo-popup.page.html',
  styleUrls: ['member-photo-popup.page.scss'],
})
export class MemberPhotoPopupComponent {
  @Input() imageSrcPath: SafeUrl;
  public showLoadingImage = true;

  constructor(
    private sanitizer: DomSanitizer,
    private modalController: ModalController,
    public translateService: TranslateService,
    public languageService: LanguageService) {
    translateService.setDefaultLang(languageService.getCurrentLanguage());
  }

  public dismissModal() {
    this.modalController.dismiss();
  }

  async ionViewWillEnter() {
    this.showLoadingImage = false;
  }

  getProfileAvatarStyles() {
    return {
      'background-image': `url(${this.imageSrcPath})`
    };
  }
}

