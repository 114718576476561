import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {LoginPageComponent} from './login/login.page';
import {MembersPageComponent} from './admin/members/members.page';
import {MemberPageComponent} from './admin/member/member.page';
import {AddPointsPageComponent} from './admin/add-points/add-points.page';
import {SubtractPointsPageComponent} from './admin/subtract-points/subtract-points.page';
import {EmptyPageComponent} from './empty/empty.page';
import {CodesPageComponent} from './admin/codes/codes.page';

const routes: Routes = [
  {
    path: 'login',
    component: LoginPageComponent
  },
  {
    path: 'members',
    component: MembersPageComponent
  },
  {
    path: 'member',
    component: MemberPageComponent
  },
  {
    path: 'add-points',
    component: AddPointsPageComponent
  },
  {
    path: 'subtract-points',
    component: SubtractPointsPageComponent
  },
  {
    path: '',
    component: CodesPageComponent,
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
