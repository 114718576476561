import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {PointsService} from '../../_services/points.service';
import {TranslateService} from '@ngx-translate/core';
import {LanguageService} from '../../_services/language.service';

@Component({
  selector: 'app-admin-add-points',
  templateUrl: 'add-points.page.html',
  styleUrls: ['add-points.page.scss'],
})
export class AddPointsPageComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private pointsService: PointsService,
    public translateService: TranslateService,
    public languageService: LanguageService) {
    translateService.setDefaultLang(languageService.getCurrentLanguage());
  }

  points: number;
  userId: number;
  comment: string;
  conditions: string[] = ['God / Mærkevarer', 'Vintage', 'God / High street', 'God / Basis', 'God, men slidt', 'Genbrug'];
  selectedConditions: string[] = [];
  showMandatoryEmptyError1 = false;
  showMandatoryEmptyError2 = false;

  async back() {
    await this.router.navigate(['/member'], {
      queryParams: {
        userId: this.userId
      }
    });
  }

  ionViewWillEnter() {
    this.points = undefined;
    this.selectedConditions = [];
    this.showMandatoryEmptyError1 = false;
    this.showMandatoryEmptyError2 = false;
  }

  async addPoints($event) {
    this.showMandatoryEmptyError2 = false;
    this.points = $event.target.value;
  }

  async updateComment($event) {
    this.comment = $event.target.value;
  }

  async changePoints() {
    if ((!this.points || this.points <= 0) && this.selectedConditions.length === 0) {
      this.showMandatoryEmptyError1 = true;
      this.showMandatoryEmptyError2 = true;
      return;
    }

    if (!this.points || this.points <= 0) {
      this.showMandatoryEmptyError2 = true;
      return;
    }

    if (this.selectedConditions.length === 0) {
      this.showMandatoryEmptyError1 = true;
      return;
    }

    await this.pointsService.changePoints(this.userId, {
      count: Math.abs(this.points),
      date: Date.now().toString(),
      comment: this.comment,
      conditions: this.selectedConditions
    });

    this.ionViewWillEnter();

    await this.router.navigate(['/member'], {
      queryParams: {
        userId: this.userId
      }
    });
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.userId = parseInt(params.userId, 10);
    });
  }

  async conditionsChanged($event) {
    this.selectedConditions = $event.detail.value;
    this.showMandatoryEmptyError1 = false;
  }

  onKeyUpNumeric(event) {
    const newValue = event.target.value;
    const regExp = new RegExp('^[0-9]+$');

    if (!regExp.test(newValue)) {
      event.target.value = newValue.slice(0, -1);
    }

    while (event.target.value.length > 1 && event.target.value[0] === '0') {
      event.target.value = event.target.value.substr(1);
    }
  }

  async logOut() {
    window.localStorage.removeItem('id');
    window.localStorage.removeItem('token');
    window.document.cookie = 'Authorization=;';
    await this.router.navigate(['/login'], { replaceUrl: true });
  }
}
