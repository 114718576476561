import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../_services/auth.service';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../_services/user.service';
import * as $ from 'jquery';
import {IonRouterOutlet} from '@ionic/angular';
import {LanguageService} from '../_services/language.service';
import { NgZone } from '@angular/core';
import { Keyboard } from '@capacitor/keyboard';
import { Plugins, Capacitor } from '@capacitor/core';
const { Modals } = Plugins;

@Component({
  selector: 'app-login',
  templateUrl: 'login.page.html',
  styleUrls: ['login.page.scss'],
})
export class LoginPageComponent implements OnInit {
  public email: string;
  public password: string;
  public invalidCredentials;
  public isKeyboardVisible = false;

  constructor(
    private route: Router,
    private authService: AuthService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private routerOutlet: IonRouterOutlet,
    public translateService: TranslateService,
    public languageService: LanguageService,
    private ngZone: NgZone) {
    translateService.setDefaultLang(languageService.getCurrentLanguage());
  }

  ngOnInit(): void {
    this.routerOutlet.swipeGesture = false;

    this.email = '';
    this.password = '';
    window.localStorage.removeItem('email');
    window.localStorage.removeItem('password');

    if (Capacitor.isNativePlatform()) {
      Keyboard.addListener('keyboardWillShow', info => {
        this.ngZone.run(() => {
          this.isKeyboardVisible = true;
          this.updateFocusState();
        });
      });

      Keyboard.addListener('keyboardDidShow', info => {
        this.ngZone.run(() => {
          this.isKeyboardVisible = true;
          this.updateFocusState();
        });
      });

      Keyboard.addListener('keyboardWillHide', () => {
        this.ngZone.run(() => {
          this.isKeyboardVisible = false;
          this.updateFocusState();
        });
      });

      Keyboard.addListener('keyboardDidHide', () => {
        this.ngZone.run(() => {
          this.isKeyboardVisible = false;
          this.updateFocusState();
        });
      });
    }
  }

  updateFocusState() {
    const activeElement = document.getElementsByClassName('email-input')[0];

    if (activeElement) {
      $(activeElement).focus();
    }

    const element = document.getElementById('loginDivId') as any;
    element.scrollToBottom();
  }

  ionViewDidEnter() {
    $('.password-input').find('input').css('-webkit-text-security', 'disc');

    const email = window.localStorage.getItem('email');

    if (!!email) {
      window.localStorage.removeItem('email');
      this.email = email;
    } else {
      this.email = '';
    }

    const password = window.localStorage.getItem('password');

    if (!!password) {
      window.localStorage.removeItem('password');
      this.password = password;
    } else {
      this.password = '';
    }

    this.isKeyboardVisible = false;
  }

  async logIn() {
    try {
      const user: any = await this.authService.login(this.email, this.password);

      if (!user.isSuccess)
      {
        window.localStorage.removeItem('id');
        window.localStorage.removeItem('token');

        console.log('Login Error', user.message);

        await Modals.alert({
          title: 'Login Error',
          message: this.translateService.instant('invalid-email-or-password')
        });

        return;
      }

      window.localStorage.setItem('id', user.id);
      window.localStorage.setItem('token', user.token);
      window.document.cookie = 'Authorization=' + user.token + '; path=/; expires=Tue, 19 Jan 2038 03:14:07 GMT';
      this.invalidCredentials = false;

      if (!user.role) {
        // Admin user should not have access to client side
        window.localStorage.removeItem('id');
        window.localStorage.removeItem('token');

        console.log('Login Error', this.translateService.instant('invalid-email-or-password'));

        /*
        await Modals.alert({
          title: 'Login Error',
          message: 'Can\'t access to client account'
        });
        */

        await Modals.alert({
          title: 'Login Error',
          message: this.translateService.instant('invalid-email-or-password')
        });

        return;
      } else {
        await this.route.navigate(['/members'], { replaceUrl: true });
        this.routerOutlet.swipeGesture = true;
      }
    } catch {
      this.invalidCredentials = true;
    }
  }

  async goToSubscribe() {
    await this.route.navigate(['/subscribe']);
  }

  async goToTermsOfUse() {
    if (!!this.email) {
      window.localStorage.setItem('email', this.email);
    }

    if (!!this.password) {
      window.localStorage.setItem('password', this.password);
    }

    await this.route.navigate(['/terms'], { queryParams: { back: 'login' } });
    this.routerOutlet.swipeGesture = true;
  }

  updateEmail($event) {
    if (this.email !== $event.target.value) {
      this.invalidCredentials = false;
    }

    this.email = $event.target.value;
  }

  updatePassword($event) {
    if (this.password !== $event.target.value) {
      this.invalidCredentials = false;
    }

    this.password = $event.target.value;
  }
}
