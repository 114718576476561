import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {PointsService} from '../../_services/points.service';
import {TranslateService} from '@ngx-translate/core';
import {LanguageService} from '../../_services/language.service';

@Component({
  selector: 'app-admin-subtract-points',
  templateUrl: 'subtract-points.page.html',
  styleUrls: ['subtract-points.page.scss'],
})
export class SubtractPointsPageComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private pointsService: PointsService,
    public translateService: TranslateService,
    public languageService: LanguageService) {
    translateService.setDefaultLang(languageService.getCurrentLanguage());
  }

  points: number;
  userId: number;
  comment: string;
  showMandatoryEmptyError = false;

  isMandatoryFieldsFull(): boolean {
    return !!this.points && this.points > 0;
  }

  async back() {
    await this.router.navigate(['/member'], {
      queryParams: {
        userId: this.userId
      }
    });
  }

  ionViewWillEnter() {
    this.points = undefined;
    this.showMandatoryEmptyError = false;
  }

  async subtractPoints($event) {
    this.showMandatoryEmptyError = false;
    this.points = $event.target.value;
  }

  async updateComment($event) {
    this.comment = $event.target.value;
  }

  async changePoints() {
    if (!this.isMandatoryFieldsFull()) {
      this.showMandatoryEmptyError = true;
      return;
    }

    await this.pointsService.changePoints(this.userId, {
      count: -Math.abs(this.points),
      date: Date.now().toString(),
      comment: this.comment,
      conditions: []
    });

    await this.router.navigate(['/member'], {
      queryParams: {
        userId: this.userId
      }
    });
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.userId = parseInt(params.userId, 10);
    });
  }

  onKeyUpNumeric(event) {
    const newValue = event.target.value;
    const regExp = new RegExp('^[0-9]+$');

    if (!regExp.test(newValue)) {
      event.target.value = newValue.slice(0, -1);
    }

    while (event.target.value.length > 1 && event.target.value[0] === '0') {
      event.target.value = event.target.value.substr(1);
    }
  }

  async logOut() {
    window.localStorage.removeItem('id');
    window.localStorage.removeItem('token');
    window.document.cookie = 'Authorization=;';
    await this.router.navigate(['/login'], { replaceUrl: true });
  }
}
