import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../_services/user.service';
import { Plugins } from '@capacitor/core';
import { IonRouterOutlet} from '@ionic/angular';
import { NavController } from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {LanguageService} from '../_services/language.service';
const { SplashScreen } = Plugins;

@Component({
  selector: 'app-empty',
  templateUrl: 'empty.page.html',
  styleUrls: ['empty.page.scss'],
})
export class EmptyPageComponent {
  constructor(
    private router: Router,
    private userService: UserService,
    private routerOutlet: IonRouterOutlet,
    public navCtrl: NavController,
    public translateService: TranslateService,
    public languageService: LanguageService) {
    translateService.setDefaultLang(languageService.getCurrentLanguage());
  }

  async ionViewWillEnter() {
    const userId = parseInt(window.localStorage.getItem('id'), 10);

    if (!!userId && !isNaN(userId)) {
      this.userService.tryGetUser(userId, async () => {
        const user = await this.userService.getUserById(userId);

        if (!user.role) {
          await this.navCtrl.navigateForward('/main', { animated: false, replaceUrl: true });
          // await this.router.navigate(['/main'], {replaceUrl: true});
          await SplashScreen.hide({ fadeOutDuration: 1000 });
        } else {
          await this.navCtrl.navigateForward('/members', { animated: false, replaceUrl: true });
          // await this.router.navigate(['/members'], {replaceUrl: true});
          await SplashScreen.hide({ fadeOutDuration: 1000 });
          this.routerOutlet.swipeGesture = true;
        }
      }, async () => {
        window.localStorage.clear();
        await this.navCtrl.navigateForward('/login', { animated: false, replaceUrl: true });
        // await this.router.navigate(['/login'], {replaceUrl: true});
        await SplashScreen.hide({ fadeOutDuration: 1000 });
      });
    } else {
      window.localStorage.clear();
      await this.navCtrl.navigateForward('/login', { animated: false, replaceUrl: true });
      // await this.router.navigate(['/login'], {replaceUrl: true});
      await SplashScreen.hide({ fadeOutDuration: 1000 });
    }
  }
}
