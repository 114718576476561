import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {PointsDto} from '../_models/pointsDto';

@Injectable()
export class PointsService {
  baseUrl = `${environment.apiUrl}Points/`;

  constructor(private http: HttpClient) { }

  async getPoints(email: string): Promise<PointsDto[]> {
    email = email.replace('+', '%2B');
    const result = this.http.get(`${this.baseUrl}${email}`, { headers: {Authorization: window.localStorage.getItem('token') }});
    return await result.pipe(map(item => item as PointsDto[])).toPromise();
  }

  async changePoints(userId: number, points: PointsDto) {
    return this.http.post(this.baseUrl + userId, points, { headers: {Authorization: window.localStorage.getItem('token') }})
      .toPromise();
  }

  formatPointsDate(date: string): string {
    const parts = date.indexOf('/') !== -1
      ? date.split('/')
      : (date.indexOf('-') !== -1
        ? date.split('-')
        : date.split('.'));
    let day: string | number = parseInt(parts[0], 10);
    day = day >= 10 ? day.toString() : '0' + day;
    let month: string | number = parseInt(parts[1], 10);
    month = month >= 10 ? month.toString() : '0' + month;
    const year = parts[2];
    return [day, month, year].join('.');
  }
}
