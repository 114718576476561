import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {DashboardDto} from '../_models/dashboardDto';

@Injectable()
export class DashboardService {
  baseUrl = `${environment.apiUrl}Dashboard/`;

  constructor(private http: HttpClient) { }

  async getDashboard(userId: number): Promise<DashboardDto> {
    const result = this.http.get(this.baseUrl + userId, { headers: {Authorization: window.localStorage.getItem('token') }});
    return await result.pipe(map(item => item as DashboardDto)).toPromise();
  }
}
