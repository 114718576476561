import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import { map } from 'rxjs/operators';
import {LoginCredentialsDto} from '../_models/loginCredentialsDto';
import {UserDto} from '../_models/userDto';

@Injectable()
export class CodesService {
  baseUrl = `${environment.apiUrl}Codes/`;

  constructor(private http: HttpClient) { }

  async getAllCodes() {
    return await this.http.get(this.baseUrl + 'all').toPromise();
  }

  async generateCode() {
    return await this.http.get(this.baseUrl + 'generate').toPromise();
  }

  async addCode(value: string, amount: number) {
    await this.http.get(this.baseUrl + `add/${value}/${amount}`).toPromise();
  }

  async delete(value: string) {
    await this.http.delete(this.baseUrl + `${value}`).toPromise();
  }
}
