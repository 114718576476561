import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../../_services/auth.service';
import {UserService} from '../../_services/user.service';
import {UserDto} from '../../_models/userDto';
import * as $ from 'jquery';
import {AlertController, IonInfiniteScroll, IonRouterOutlet} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {PointsService} from '../../_services/points.service';
import * as signalR from '@microsoft/signalr';
import { environment } from '../../../environments/environment';
import {LanguageService} from '../../_services/language.service';
import QrScanner from 'qr-scanner';

@Component({
  selector: 'app-admin-members',
  templateUrl: 'members.page.html',
  styleUrls: ['members.page.scss'],
})
export class MembersPageComponent implements OnInit  {
  constructor(
    private route: Router,
    private authService: AuthService,
    private userService: UserService,
    private alertController: AlertController,
    private pointsService: PointsService,
    private routerOutlet: IonRouterOutlet,
    public translateService: TranslateService,
    public languageService: LanguageService) {
    translateService.setDefaultLang(languageService.getCurrentLanguage());
  }

  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;

  users: UserDto[];
  connection: signalR.HubConnection;
  searchingValue: string = null;
  pagesCount = 1;
  page = 1;
  orderFieldId = -1;
  orderFieldName = null;
  orderFieldDesc = false;
  qrScanner: QrScanner = null;

  loadData(event) {
    setTimeout(async () => {
      if (event) {
        event.target.complete();
      }

      await this.selectUsersPage(this.page + 1);
    }, 500);
  }

  async destroyScanner() {
    if (this.qrScanner) {
      if (this.qrScanner.isFlashOn()) {
        await this.qrScanner.turnFlashOff();
      }

      this.qrScanner.stop();
      this.qrScanner.destroy();
      this.qrScanner = null;
      $('.scan-region-highlight').remove();
    }
  }

  ngOnInit() {
    $('.qr-scanner-close-button').on('click', async () => {
      $('ion-app').show();
      await this.destroyScanner();
      $('.qr-scanner-layer').hide();
    });

    this.loadData(null);
  }

  ionViewDidEnter() {
    // @ts-ignore
    document.getElementsByClassName('searchbar-clear-icon')[0].style.marginRight = '-60px';
  }

  async fillStatuses(users = null)
  {
    const emails = (!users ? this.users : users).filter(user => !!user.email).map(user => user.email).join(',');
    const statuses = await this.userService.getUserStatuses(emails);

    for (const user of this.users)
    {
      // @ts-ignore
      for (const status of statuses.statuses)
      {
        if (user.email === status.email)
        {
          user.status = status.userStatus;
        }
      }
    }
  }

  async ionViewWillEnter() {
    this.pagesCount = await this.userService.getPagesCount();

    if (this.pagesCount < this.page) {
      this.page = this.pagesCount;

      if (this.page < 1) {
        this.page = 1;
      }
    }

    this.page = 1;
    this.users = await this.userService.getUsers(this.searchingValue, this.page, this.orderFieldName, this.orderFieldDesc);
    this.pagesCount = await this.userService.getPagesCount();

    this.routerOutlet.swipeGesture = true;

    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(environment.hubUrl, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets
      })
      .configureLogging(signalR.LogLevel.Debug)
      .build();

    this.connection.on('subscription_update', async email => {
      await this.calcPagesCount();
      this.users = await this.userService.getUsers(this.searchingValue, this.page, this.orderFieldName, this.orderFieldDesc);
    });

    this.connection.start().catch(err => console.log(err));
  }

  async calcPagesCount() {
    this.pagesCount = await this.userService.getPagesCount(this.searchingValue);

    if (this.pagesCount < this.page) {
      this.page = this.pagesCount;

      if (this.page < 1) {
        this.page = 1;
      }
    }
  }

  ionViewWillLeave() {
    this.connection?.stop().then(() => {});
    this.connection = null;
    this.destroyScanner().then(() => {});
  }

  async logOut() {
    window.localStorage.removeItem('id');
    window.localStorage.removeItem('token');
    window.document.cookie = 'Authorization=;';
    await this.route.navigate(['/login'], { replaceUrl: true });
  }

  async goToMember(email: string) {
    email = email.replace('+', '%2B');

    await this.route.navigate(['/member'], {
      queryParams: {
        userEmail: email
      }
    });
  }

  async scanQr() {
    $('ion-app').hide();
    $('.qr-scanner-layer').show();
    $('.scan-region-highlight').remove();

    const scannerLayer = $('.qr-scanner-layer-middle');
    scannerLayer
      .css('z-index', '-1')
      .css('background-color', 'black');

    $('#qr-scanner-video-element').remove();
    const videoElement = document.createElement('video');
    $(videoElement)
      .attr('id', 'qr-scanner-video-element')
      .css('position', 'absolute')
      .css('left', '0')
      .css('top', '0')
      .css('width', '100%')
      .css('height', '100%')
      .css('opacity', '0');
    scannerLayer.append(videoElement);

    this.qrScanner = new QrScanner(videoElement, result => {
      this.destroyScanner();
      $('#qr-scanner-video-element').remove();
      $('.scan-region-highlight').remove();
      $('ion-app').show();
      $('.qr-scanner-layer').hide();

      const json = JSON.parse(result.data);

      this.userService.getUserById(json.Id).then(user => {
        this.goToMember(user.email);
      });
    }, { highlightScanRegion: true });

    await this.qrScanner.start();
    $(videoElement).css('opacity', '1');

    const scannerLightButton = $('.qr-scanner-light-button');
    scannerLightButton.hide();

    this.qrScanner.hasFlash().then(available => {
      if (available) {
        $('.qr-scanner-light-button').show();

        scannerLightButton.on('click', async () => {
          $(videoElement).css('opacity', '0');
          await this.qrScanner.toggleFlash();
          $(videoElement).css('opacity', '1');
        });
      }
    });
  }

  async searchbarChange($event) {
    this.searchingValue = $event.srcElement.value;
    this.users = [];
    this.page = 1;
    await this.calcPagesCount();
    this.users = await this.userService.getUsers(this.searchingValue, this.page, this.orderFieldName, this.orderFieldDesc);
  }

  async clearSearchbar() {
    // @ts-ignore
    document.getElementsByClassName('searchbar')[0].value = '';
  }

  getUserStatusString(statusId: number): string {
    return this.userService.getUserStatusString(statusId);
  }

  getButtonStyle(user: UserDto) {
    const color: string = this.userService.getUserStatusColor(user.status);

    return {
      background: color
    };
  }

  formatPointsDate(date: string): string {
    return this.pointsService.formatPointsDate(date);
  }

  async selectUsersPage(page: number) {
    this.page = page;

    if (this.page > this.pagesCount) {
      this.page = this.pagesCount;
    }

    if (this.page < 1) {
      this.page = 1;
    }

    const newUsers = await this.userService.getUsers(this.searchingValue, page, this.orderFieldName, this.orderFieldDesc);

    for (const newUser of newUsers) {
      this.users.push(newUser);
    }
  }

  async setOrderField(id: number, name: string): Promise<void> {
    if (id === this.orderFieldId) {
      this.orderFieldDesc = !this.orderFieldDesc;
    } else {
      this.orderFieldDesc = false;
      this.orderFieldId = id;
      this.orderFieldName = name;
    }

    this.users = [];
    this.page = 1;
    await this.calcPagesCount();
    this.users = await this.userService.getUsers(this.searchingValue, this.page, this.orderFieldName, this.orderFieldDesc);
  }

  getOrderFieldText(id: number): string {
    if (this.orderFieldId !== id) {
      return '';
    }

    if (this.orderFieldDesc) {
      return '↑';
    }

    return '↓';
  }
}
