import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy, iosTransitionAnimation } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { UserService } from './_services/user.service';
import { DashboardService } from './_services/dashboard.service';
import { PointsService } from './_services/points.service';
import { LoginPageComponent } from './login/login.page';
import { AuthService } from './_services/auth.service';
import { MembersPageComponent } from './admin/members/members.page';
import { MemberPageComponent } from './admin/member/member.page';
import { EmptyPageComponent } from './empty/empty.page';
import { AddPointsPageComponent } from './admin/add-points/add-points.page';
import { SubtractPointsPageComponent } from './admin/subtract-points/subtract-points.page';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { MemberPhotoPopupComponent } from './admin/member-photo-popup/member-photo-popup.page';
import { MemberPointsPopupComponent } from './admin/member-points-popup/member-points-popup.page';
import { LoaderStubComponent } from './loader-stub/loader-stub';
import { LocalStorageService } from './_services/localStorage.service';
import { LanguageService } from './_services/language.service';
import { CodesPageComponent } from './admin/codes/codes.page';
import {CodesService} from './_services/codes.service';

@NgModule({
  declarations: [
    AppComponent,
    LoginPageComponent,
    MembersPageComponent,
    MemberPageComponent,
    EmptyPageComponent,
    AddPointsPageComponent,
    SubtractPointsPageComponent,
    MemberPhotoPopupComponent,
    MemberPointsPopupComponent,
    LoaderStubComponent,
    CodesPageComponent,
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      navAnimation: iosTransitionAnimation,
      swipeBackEnabled: true
    }),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AppRoutingModule,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    UserService,
    DashboardService,
    PointsService,
    AuthService,
    LocalStorageService,
    LanguageService,
    CodesService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
