import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../_services/auth.service';
import {UserService} from '../../_services/user.service';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {PointsService} from '../../_services/points.service';
import {LoadingController} from '@ionic/angular';
import { AlertController } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {LanguageService} from '../../_services/language.service';
import {CodesService} from '../../_services/codes.service';

@Component({
  selector: 'app-admin-codes',
  templateUrl: 'codes.page.html',
  styleUrls: ['codes.page.scss'],
})
export class CodesPageComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
    private codesService: CodesService,
    private pointsService: PointsService,
    private sanitizer: DomSanitizer,
    private loadingController: LoadingController,
    private alertController: AlertController,
    private modalController: ModalController,
    public translateService: TranslateService,
    public languageService: LanguageService) {
    translateService.setDefaultLang(languageService.getCurrentLanguage());
  }

  codes: any = [];
  codeValue = '';
  pointsCount = 0;
  roomCodes = [];
  newDoorId = '';
  newDoorPassword = '';

  async ngOnInit() {
    this.codes = await this.codesService.getAllCodes();
    this.codes.reverse();
    this.codeValue = (await this.codesService.generateCode()).toString();

    this.userService.getDoorCode().then(codes => {
      // @ts-ignore
      this.roomCodes = codes;
    });
  }

  saveRoomCode(room) {
    this.userService.saveDoorCode(room.doorId, room.doorPassword).then(_ => {
      alert(`Room ${room.doorId} code saved.`);
    });
  }

  doorPasswordChange(event, room) {
    room.doorPassword = event.detail.value;
  }

  deleteRoomCode(room) {
    this.userService.deleteDoorCode(room.doorId).then(_ => {
      this.userService.getDoorCode().then(codes => {
        // @ts-ignore
        this.roomCodes = codes;
      });
    });
  }

  newDoorIdChanged(event) {
    this.newDoorId = event.detail.value;
  }

  newDoorPasswordChanged(event) {
    this.newDoorPassword = event.detail.value;
  }

  addDoor() {
    if (!this.newDoorId.trim().length || !this.newDoorPassword.trim().length) {
      return;
    }

    for (const roomCode of this.roomCodes) {
      if (roomCode.doorId.trim() === this.newDoorId.trim()) {
        alert(`Room ${this.newDoorId.trim()} already exists.`);
        return;
      }
    }

    this.userService.saveDoorCode(this.newDoorId.trim(), this.newDoorPassword.trim()).then(_ => {
      this.userService.getDoorCode().then(codes => {
        // @ts-ignore
        this.roomCodes = codes;
      });
    });
  }

  getAllCodes() {
    console.log(this.codes);
    return this.codes;
  }

  async addCode() {
    if (this.pointsCount === 0) {
      return;
    }

    await this.codesService.addCode(this.codeValue, this.pointsCount);
    this.codeValue = (await this.codesService.generateCode()).toString();
    this.pointsCount = 0;
    this.codes = await this.codesService.getAllCodes();
    this.codes.reverse();
  }

  async pointsCountChange(event) {
    this.pointsCount = event.detail.value;
  }

  async delete(value: string) {
    await this.codesService.delete(value);
    this.codeValue = (await this.codesService.generateCode()).toString();
    this.pointsCount = 0;
    this.codes = await this.codesService.getAllCodes();
    this.codes.reverse();
  }
}
