export class UserDto {
  public id: number;
  public firstName: string;
  public lastName: string;
  public email: string;
  public phone: string;
  public company: string;
  public address: string;
  public balance: number;
  public memberSince: Date;
  public status: number;
  public isPhotoExists: boolean;
  public role: number;
  public handle: string;
}
